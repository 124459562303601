<template>
	<div class="main-content">
		<ktv-breadcrumb
			:folder="$t('Loan', 'Loan')"
			:page="$t('Institution', 'Institution')"
			:title="$route.params.id ? $t('Institution Update', 'Institution Update') : $t('Institution Add', 'Institution Add')"
		/>
		<b-row>
			<b-col md="12 mb-30">
				<b-row>
					<b-col>
						<h1>{{ $t("Institution Data", "Institution Data") }}</h1>
					</b-col>
				</b-row>
				<b-row class="mb-4">
					<b-col>
						<router-link v-b-tooltip.hover :to="{ name: 'Loan Institution List' }">
							<ktv-back :label="$t('Back to institution list', 'Back to institution list')" />
						</router-link>
					</b-col>
				</b-row>
				<div>
					<b-card
						:header="$t('Institution Basic Data', 'Institution Basic Data')"
						align="left"
						border-variant="danger"
						header-bg-variant="primary"
						header-text-variant="white"
					>
						<b-alert v-if="is.loading" variant="primary" show> Loading... </b-alert>
						<b-tabs v-if="!is.loading" active-nav-item-class="nav nav-tabs">
							<basic-data :active-tab="activeTab" :institution="institutionData" @on-tab-change="($event) => (activeTab = $event)" />
							<staff-access v-if="$route.params.id" :active-tab="activeTab" @on-tab-change="($event) => (activeTab = $event)" />
						</b-tabs>
					</b-card>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import { KtvBack } from "@/components"
	import { mapActions, mapGetters } from "vuex"
	import BasicData from "./_BasicData"
	import StaffAccess from "./_StaffAccess"

	export default {
		name: "LoanInstitutionAdd",
		metaInfo: {
			title: "Institution Add",
		},
		components: { BasicData, KtvBack, StaffAccess },
		data() {
			return {
				activeTab: "Basic Data",
				is: {
					loading: false,
				},
			}
		},
		computed: {
			...mapGetters({
				institutionData: "INSTITUTION/institutionData",
			}),
		},
		created() {
			if (this.$route.params.id) {
				this.getData()
			}
		},
		beforeDestroy() {
			this.resetInstitution()
		},
		methods: {
			...mapActions({
				getInstitution: "INSTITUTION/getInstitution",
				resetInstitution: "INSTITUTION/resetInstitution",
			}),
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getInstitution(this.$route.params.id).then(() => {
					this.is = {
						...this.is,
						loading: false,
					}
				})
			},
		},
	}
</script>
