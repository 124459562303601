<template>
	<b-tab :title="$t('Basic Data', 'Basic Data')" :active="activeTab === 'Basic Data'" @click="$emit('on-tab-change', 'Basic Data')">
		<ktv-form label="Loan Institution Add Basic Data" @submit="save">
			<template #content="{ invalid }">
				<b-row>
					<b-col>
						<ktv-accordion name="institutionInformation" :title="$t('Institution Information', 'Institution Information')">
							<template #content>
								<div v-if="localInstitution">
									<b-row v-if="$route.params.id">
										<ktv-input
											v-model="localInstitution.loanInstitutionId"
											:label="$t('Institution ID', 'Institution ID')"
											:readonly="true"
											rules="required"
											type="number"
										/>
									</b-row>
									<b-row>
										<ktv-input
											v-model="localInstitution.createdDate"
											:label="$t('Date of Data Collection', 'Date of Data Collection')"
											rules="required"
											type="date"
										/>
									</b-row>
									<b-row>
										<ktv-input
											v-model="localInstitution.institutionName"
											:label="$t('Institution Name', 'Institution Name')"
											rules="required"
										/>
									</b-row>
									<b-row>
										<ktv-button-group
											v-model="is.childInstitution"
											:label="$t('Is Child Institution?', 'Is Child Institution?')"
											:options="institutionTypeOptions"
											rules="required"
											@on-click="changeChildInstitution"
										/>
									</b-row>
									<b-row v-if="is.childInstitution">
										<ktv-select
											v-model="localInstitution.parentId"
											:label="$t('Parent Institution', 'Parent Institution')"
											:options="organisationNameOptions"
											:rules="is.childInstitution ? 'required' : null"
										/>
									</b-row>
									<b-row>
										<ktv-select
											v-model="localInstitution.legalStatus"
											:label="$t('Legal Status', 'Legal Status')"
											:options="legalStatusOptions"
											rules="required"
										/>
									</b-row>
									<b-row>
										<ktv-select
											v-model="localInstitution.yearEstablished"
											:label="$t('Established Year', 'Established Year')"
											:options="establishedYearOptions"
										/>
									</b-row>
									<b-row>
										<ktv-select
											v-model="localInstitution.countryId"
											:label="$t('Country', 'Country')"
											:options="countryOptions"
											rules="required"
										/>
									</b-row>
									<b-row>
										<ktv-select
											v-model="localInstitution.provinceId"
											:disabled="!localInstitution.countryId"
											:label="$t('Province', 'Province')"
											:options="provinceOptions"
											rules="required"
										/>
									</b-row>
									<b-row>
										<ktv-select
											v-model="localInstitution.districtId"
											:disabled="!localInstitution.provinceId"
											:label="$t('District', 'District')"
											:options="districtOptions"
											rules="required"
										/>
									</b-row>
									<b-row>
										<ktv-select
											v-model="localInstitution.subDistrictId"
											:disabled="!localInstitution.districtId"
											:label="$t('Sub District', 'Sub District')"
											:options="subDistrictOptions"
											rules="required"
										/>
									</b-row>
									<b-row>
										<ktv-select
											v-model="localInstitution.villageId"
											:disabled="!localInstitution.subDistrictId"
											:label="$t('Village', 'Village')"
											:options="villageOptions"
											rules="required"
										/>
									</b-row>
									<b-row>
										<ktv-textarea v-model="localInstitution.address" rules="required" :label="$t('Address', 'Address')" />
									</b-row>
									<b-row>
										<ktv-input v-model="localInstitution.npwp" :label="$t('NPWP', 'NPWP')" />
									</b-row>
								</div>
							</template>
						</ktv-accordion>
					</b-col>
					<b-col>
						<ktv-accordion name="communicationAndMedia" :title="$t('Communication and Media', 'Communication and Media')">
							<template #content>
								<div>
									<b-row>
										<ktv-image-upload
											:current-file="localInstitution.photo"
											:file="institutionPhoto"
											:label="$t('Photo', 'Photo')"
											:rules="!$route.params.id || !localInstitution.photo ? 'required' : ''"
											class="col-md-12 my-2"
											@on-added="onAddedPhoto"
										/>
									</b-row>
									<b-row>
										<ktv-image-upload
											:current-file="localInstitution.companyLogo"
											:file="institutionLogo"
											:label="$t('Logo', 'Logo')"
											:rules="!$route.params.id || !localInstitution.companyLogo ? 'required' : ''"
											class="col-md-12 my-2"
											@on-added="onAddedLogo"
										/>
									</b-row>
									<b-row>
										<ktv-input v-model="localInstitution.email" :label="$t('Email', 'Email')" rules="required|email" />
									</b-row>
									<b-row>
										<ktv-input
											v-model="localInstitution.phoneNumber"
											:label="$t('Telephone Number', 'Telephone Number')"
											rules="required"
										/>
									</b-row>
								</div>
								<ktv-accordion
									:title="$t('Institution Representative Contact', 'Institution Representative Contact')"
									name="institutionRepresentativeContact"
								>
									<template #content>
										<div>
											<b-row>
												<ktv-image-upload
													:current-file="localInstitutionStaff.photo"
													:file="institutionStaffPhoto"
													:label="$t('Photo', 'Photo')"
													:rules="!$route.params.id || !localInstitutionStaff.photo ? 'required' : ''"
													class="col-md-12 my-2"
													@on-added="onAddedStaffPhoto"
												/>
											</b-row>
											<b-row>
												<ktv-input v-model="localInstitutionStaff.personName" :label="$t('Name', 'Name')" rules="required" />
											</b-row>
											<b-row>
												<ktv-select
													v-model="localInstitutionStaff.organisationRole"
													:label="$t('Position', 'Position')"
													:options="organisationRoleOptions"
													rules="required"
												/>
											</b-row>
											<b-row>
												<ktv-input
													v-model="localInstitutionStaff.birthDate"
													:label="$t('Birth Date', 'Birth Date')"
													:max="maxDate"
													:min="minDate"
													type="date"
												/>
											</b-row>
											<b-row>
												<b-form-group v-slot="{ ariaDescribedby }" class="col-md-12 mb-2" :label="$t('Gender', 'Gender')">
													<b-form-radio-group
														id="radio-group-gender"
														v-model="localInstitutionStaff.gender"
														:aria-describedby="ariaDescribedby"
														:options="genderOptions"
														name="radio-gender"
														readonly
													/>
												</b-form-group>
											</b-row>
											<b-row>
												<ktv-input v-model="localInstitutionStaff.useremail" :label="$t('Email', 'Email')" rules="required|email" />
											</b-row>
											<b-row>
												<ktv-input
													v-model="localInstitutionStaff.phoneNumber"
													:label="$t('Phone Number', 'Phone Number')"
													rules="required"
												/>
											</b-row>
										</div>
									</template>
								</ktv-accordion>
							</template>
						</ktv-accordion>
					</b-col>
				</b-row>
				<div class="bg-gray-100 p-4 mt-4" align="center">
					<ktv-button :loading="is.loading" :text="$t('Save', 'Save')" icon="save" :disabled="invalid" type="submit" />
				</div>
			</template>
		</ktv-form>
	</b-tab>
</template>

<script>
	import { KtvAccor as KtvAccordion, KtvButton, KtvButtonGroup, KtvForm, KtvImageUpload, KtvInput, KtvSelect, KtvTextarea } from "@/components"
	import { filter, findWhere, map, range } from "underscore"
	import { mapActions, mapState } from "vuex"
	import moment from "moment"

	export default {
		name: "LoanInstitutionAddBasicData",
		metaInfo: {
			title: "Institution Add - Basic Data",
		},
		components: { KtvAccordion, KtvButton, KtvButtonGroup, KtvForm, KtvImageUpload, KtvInput, KtvSelect, KtvTextarea },
		props: {
			activeTab: {
				default: null,
				required: true,
				type: String,
			},
			institution: {
				default: () => {},
				required: false,
				type: Object,
			},
		},
		emits: ["on-tab-change"],
		data() {
			return {
				countryOptions: [],
				districtOptions: [],
				establishedYearOptions: [],
				genderOptions: [],
				institutionLogo: null,
				institutionPhoto: null,
				institutionStaffPhoto: null,
				institutionTypeOptions: [
					{
						text: this.$t("Yes", "Yes"),
						value: true,
					},
					{
						text: this.$t("No", "No"),
						value: false,
					},
				],
				is: {
					childInstitution: false,
					loading: false,
				},
				legalStatusOptions: [],
				localInstitution: {
					address: null,
					countryId: null,
					companyLogo: null,
					createdDate: null,
					districtId: null,
					email: null,
					institutionName: null,
					legalStatus: null,
					loanInstitutionId: null,
					npwp: null,
					parentId: 0,
					phoneNumber: null,
					photo: null,
					provinceId: null,
					subDistrictId: null,
					villageId: null,
					yearEstablished: null,
				},
				localInstitutionStaff: {
					birthDate: null,
					gender: null,
					organisationRole: null,
					phoneNumber: null,
					photo: null,
					useremail: null,
				},
				organisationNameOptions: [],
				organisationRoleOptions: [],
				provinceOptions: [],
				subDistrictOptions: [],
				villageOptions: [],
			}
		},
		computed: {
			...mapState("OTHERS", [
				"countryList",
				"districtList",
				"genderList",
				"legalStatusList",
				"organisationNameList",
				"organisationRoleList",
				"provinceList",
				"subDistrictList",
				"villageList",
			]),
			maxDate() {
				const d = new Date()
				const year = d.getFullYear()
				const month = d.getMonth()
				const day = d.getDate()
				let maxDate = new Date(year - 17, month, day)

				const offset = maxDate.getTimezoneOffset()
				maxDate = new Date(maxDate.getTime() - offset * 60 * 1000)

				return moment(maxDate).format("yyyy-MM-DD")
			},
			minDate() {
				const d = new Date()
				const year = d.getFullYear()
				const month = d.getMonth()
				const day = d.getDate()
				let minDate = new Date(year - 100, month, day)

				const offset = minDate.getTimezoneOffset()
				minDate = new Date(minDate.getTime() - offset * 60 * 1000)

				return moment(minDate).format("yyyy-MM-DD")
			},
		},
		watch: {
			countryList: {
				deep: true,
				handler() {
					this.getCountryOptions()
				},
				immediate: true,
			},
			districtList: {
				deep: true,
				handler() {
					this.getDistrictOptions()
				},
				immediate: true,
			},
			genderList: {
				deep: true,
				handler() {
					this.getGenderOptions()
				},
				immediate: true,
			},
			institution: {
				deep: true,
				handler(institution) {
					if (institution) {
						this.localInstitution = Object.assign({}, institution)
						this.localInstitutionStaff = Object.assign({}, institution.institutionStaffList)
						this.is = {
							...this.is,
							childInstitution: !!institution.parentId && institution.parentId > 0,
						}

						if (this.is.childInstitution) {
							this.getOrganisationNameList()
						}
					}
				},
				immediate: true,
			},
			"is.childInstitution": {
				handler() {
					if (this.is.childInstitution) {
						this.getOrganisationNameList()
					} else {
						this.localInstitution = {
							...this.localInstitution,
							parentId: 0,
						}
					}
				},
			},
			legalStatusList: {
				deep: true,
				handler() {
					this.getLegalStatusOptions()
				},
				immediate: true,
			},
			"localInstitution.countryId": {
				handler(newValue, oldValue) {
					if (newValue) {
						if (newValue !== oldValue) {
							if (oldValue) {
								this.localInstitution = {
									...this.localInstitution,
									provinceId: null,
								}
							}

							const country = findWhere(this.countryList, { entityCountryID: String(this.localInstitution.countryId) })

							if (country && country.countryCode) {
								this.getProvinceList(country.countryCode)
							}
						}
					} else if (!newValue && oldValue) {
						this.localInstitution = {
							...this.localInstitution,
							provinceId: null,
						}
					}
				},
				immediate: true,
			},
			"localInstitution.districtId": {
				handler(newValue, oldValue) {
					if (newValue) {
						if (newValue !== oldValue) {
							if (oldValue) {
								this.localInstitution = {
									...this.localInstitution,
									subDistrictId: null,
								}
							}

							this.getSubDistrictList(this.localInstitution.districtId)
						}
					} else if (!newValue && oldValue) {
						this.localInstitution = {
							...this.localInstitution,
							subDistrictId: null,
						}
					}
				},
				immediate: true,
			},
			"localInstitution.provinceId": {
				handler(newValue, oldValue) {
					if (newValue) {
						if (newValue !== oldValue) {
							if (oldValue) {
								this.localInstitution = {
									...this.localInstitution,
									districtId: null,
								}
							}

							this.getDistrictList(this.localInstitution.provinceId)
						}
					} else if (!newValue && oldValue) {
						this.localInstitution = {
							...this.localInstitution,
							districtId: null,
						}
					}
				},
				immediate: true,
			},
			"localInstitution.subDistrictId": {
				handler(newValue, oldValue) {
					if (newValue) {
						if (newValue !== oldValue) {
							if (oldValue) {
								this.localInstitution = {
									...this.localInstitution,
									villageId: null,
								}
							}

							this.getVillageList(this.localInstitution.subDistrictId)
						}
					} else if (!newValue && oldValue) {
						this.localInstitution = {
							...this.localInstitution,
							villageId: null,
						}
					}
				},
				immediate: true,
			},
			organisationNameList: {
				deep: true,
				handler() {
					this.getOrganisationNameOptions()
				},
				immediate: true,
			},
			organisationRoleList: {
				deep: true,
				handler() {
					this.getOrganisationRoleOptions()
				},
				immediate: true,
			},
			provinceList: {
				deep: true,
				handler() {
					this.getProvinceOptions()
				},
				immediate: true,
			},
			subDistrictList: {
				deep: true,
				handler() {
					this.getSubDistrictOptions()
				},
				immediate: true,
			},
			villageList: {
				deep: true,
				handler() {
					this.getVillageOptions()
				},
				immediate: true,
			},
		},
		created() {
			this.getCountryList()
			this.getEstablishedYearOptions()
			this.getGenderList()
			this.getLegalStatusList()
			this.getOrganisationRoleList()
		},
		methods: {
			...mapActions({
				createInstitution: "INSTITUTION/createInstitution",
				getCountryList: "OTHERS/getCountryList",
				getDistrictList: "OTHERS/getDistrictList",
				getGenderList: "OTHERS/getGenderList",
				getLegalStatusList: "OTHERS/getLegalStatusList",
				getOrganisationNameList: "OTHERS/getOrganisationNameList",
				getOrganisationRoleList: "OTHERS/getOrganisationRoleList",
				getProvinceList: "OTHERS/getProvinceList",
				getSubDistrictList: "OTHERS/getSubDistrictList",
				getVillageList: "OTHERS/getVillageList",
				updateInstitution: "INSTITUTION/updateInstitution",
				uploadInstitutionImage: "INSTITUTION/uploadInstitutionImage",
			}),
			changeChildInstitution(isChildInstitution) {
				this.is = {
					...this.is,
					childInstitution: isChildInstitution,
				}
			},
			getCountryOptions() {
				this.countryOptions = map(this.countryList, (country) => {
					return {
						text: country.country,
						value: parseInt(country.entityCountryID, 10),
					}
				})
			},
			getDistrictOptions() {
				this.districtOptions = map(this.districtList, (district) => {
					return {
						text: district.district,
						value: parseInt(district.entityDistrictID, 10),
					}
				})
			},
			getEstablishedYearOptions() {
				const yearOptions = range(1900, new Date().getFullYear() + 1)

				this.establishedYearOptions = map(yearOptions, (estYear) => {
					return {
						text: estYear,
						value: estYear,
					}
				})

				this.establishedYearOptions = this.establishedYearOptions.reverse()
			},
			getGenderOptions() {
				this.genderOptions = map(this.genderList, (gender) => {
					return {
						text: gender.value.charAt(0).toUpperCase() + gender.value.slice(1),
						value: parseInt(gender.entityListOfValueId, 10),
					}
				})
			},
			getLegalStatusOptions() {
				this.legalStatusOptions = map(this.legalStatusList, (legalStatus) => {
					return {
						text: legalStatus.value,
						value: parseInt(legalStatus.entityListOfValueId, 10),
					}
				})
			},
			getOrganisationNameOptions() {
				const filteredOrganisationNameList = filter(
					this.organisationNameList,
					(organisationName) => organisationName.loanInstitutionId !== this.localInstitution.loanInstitutionId,
				)

				this.organisationNameOptions = map(filteredOrganisationNameList, (organisationName) => {
					return {
						text: organisationName.institutionName,
						value: parseInt(organisationName.loanInstitutionId, 10),
					}
				})
			},
			getOrganisationRoleOptions() {
				this.organisationRoleOptions = map(this.organisationRoleList, (organisationRole) => {
					return {
						text: organisationRole.value,
						value: parseInt(organisationRole.entityListOfValueId, 10),
					}
				})
			},
			getProvinceOptions() {
				this.provinceOptions = map(this.provinceList, (province) => {
					return {
						text: province.province,
						value: parseInt(province.entityProvinceID, 10),
					}
				})
			},
			getSubDistrictOptions() {
				this.subDistrictOptions = map(this.subDistrictList, (subDistrict) => {
					return {
						text: subDistrict.subDistrict,
						value: parseInt(subDistrict.entitySubDistrictID, 10),
					}
				})
			},
			getVillageOptions() {
				this.villageOptions = map(this.villageList, (village) => {
					return {
						text: village.village,
						value: parseInt(village.entityVillageID, 10),
					}
				})
			},
			onAddedLogo(file) {
				this.institutionLogo = file
			},
			onAddedPhoto(file) {
				this.institutionPhoto = file
			},
			onAddedStaffPhoto(file) {
				this.institutionStaffPhoto = file
			},
			reset() {
				this.institutionLogo = null
				this.institutionPhoto = null
				this.institutionStaffPhoto = null
				this.is = {
					...this.is,
					childInstitution: false,
					loading: false,
				}
				this.localInstitution = Object.assign(
					{},
					{
						address: null,
						companyLogo: null,
						countryId: null,
						createdDate: null,
						districtId: null,
						email: null,
						institutionName: null,
						legalStatus: null,
						loanInstitutionId: null,
						npwp: null,
						parentId: 0,
						phoneNumber: null,
						photo: null,
						provinceId: null,
						subDistrictId: null,
						villageId: null,
						yearEstablished: null,
					},
				)
				this.localInstitutionStaff = Object.assign(
					{},
					{
						birthDate: null,
						gender: null,
						organisationRole: null,
						phoneNumber: null,
						photo: null,
						useremail: null,
					},
				)
			},
			save() {
				this.is = {
					...this.is,
					loading: true,
				}

				if (!this.localInstitution.parentId) {
					this.localInstitution = {
						...this.localInstitution,
						parentId: 0,
					}
				}

				if (!this.localInstitution.loanInstitutionId) {
					// create
					if (this.institutionPhoto) {
						if (this.institutionLogo) {
							if (this.institutionStaffPhoto) {
								// institution photo + institution logo + staff photo
								Promise.all([
									this.uploadInstitutionImage({
										file: this.institutionPhoto,
										folder: "photo",
									}),
									this.uploadInstitutionImage({
										file: this.institutionLogo,
										folder: "logo",
									}),
									this.uploadInstitutionImage({
										file: this.institutionStaffPhoto,
										folder: "staff",
									}),
								]).then(([photoURL, logoURL, staffPhotoURL]) => {
									this.createInstitution({
										...this.localInstitution,
										companyLogo: logoURL,
										institutionStaffList: {
											...this.localInstitutionStaff,
											photo: staffPhotoURL,
										},
										photo: photoURL,
									})
										.then(() => {
											this.is = {
												...this.is,
												loading: false,
											}
											this.reset()
										})
										.catch(() => {
											this.is = {
												...this.is,
												loading: false,
											}
										})
								})
							} else {
								// institution photo + institution logo
								Promise.all([
									this.uploadInstitutionImage({
										file: this.institutionPhoto,
										folder: "photo",
									}),
									this.uploadInstitutionImage({
										file: this.institutionLogo,
										folder: "logo",
									}),
								]).then(([photoURL, logoURL]) => {
									this.createInstitution({
										...this.localInstitution,
										companyLogo: logoURL,
										photo: photoURL,
									})
										.then(() => {
											this.is = {
												...this.is,
												loading: false,
											}
											this.reset()
										})
										.catch(() => {
											this.is = {
												...this.is,
												loading: false,
											}
										})
								})
							}
						} else if (this.institutionStaffPhoto) {
							// institution photo + staff photo
							Promise.all([
								this.uploadInstitutionImage({
									file: this.institutionPhoto,
									folder: "photo",
								}),
								this.uploadInstitutionImage({
									file: this.institutionStaffPhoto,
									folder: "staff",
								}),
							]).then(([photoURL, staffPhotoURL]) => {
								this.createInstitution({
									...this.localInstitution,
									institutionStaffList: {
										...this.localInstitutionStaff,
										photo: staffPhotoURL,
									},
									photo: photoURL,
								})
									.then(() => {
										this.is = {
											...this.is,
											loading: false,
										}
										this.reset()
									})
									.catch(() => {
										this.is = {
											...this.is,
											loading: false,
										}
									})
							})
						} else {
							// institution photo
							Promise.all([
								this.uploadInstitutionImage({
									file: this.institutionPhoto,
									folder: "photo",
								}),
							]).then(([photoURL]) => {
								this.createInstitution({
									...this.localInstitution,
									photo: photoURL,
								})
									.then(() => {
										this.is = {
											...this.is,
											loading: false,
										}
										this.reset()
									})
									.catch(() => {
										this.is = {
											...this.is,
											loading: false,
										}
									})
							})
						}
					} else if (this.institutionLogo) {
						if (this.institutionStaffPhoto) {
							// institution logo + staff photo
							Promise.all([
								this.uploadInstitutionImage({
									file: this.institutionLogo,
									folder: "logo",
								}),
								this.uploadInstitutionImage({
									file: this.institutionStaffPhoto,
									folder: "staff",
								}),
							]).then(([logoURL, staffPhotoURL]) => {
								this.createInstitution({
									...this.localInstitution,
									companyLogo: logoURL,
									institutionStaffList: {
										...this.localInstitutionStaff,
										photo: staffPhotoURL,
									},
								})
									.then(() => {
										this.is = {
											...this.is,
											loading: false,
										}
										this.reset()
									})
									.catch(() => {
										this.is = {
											...this.is,
											loading: false,
										}
									})
							})
						} else {
							// institution logo
							Promise.all([
								this.uploadInstitutionImage({
									file: this.institutionLogo,
									folder: "logo",
								}),
							]).then(([logoURL]) => {
								this.createInstitution({
									...this.localInstitution,
									companyLogo: logoURL,
								})
									.then(() => {
										this.is = {
											...this.is,
											loading: false,
										}
										this.reset()
									})
									.catch(() => {
										this.is = {
											...this.is,
											loading: false,
										}
									})
							})
						}
					} else if (this.institutionStaffPhoto) {
						// staff photo
						Promise.all([
							this.uploadInstitutionImage({
								file: this.institutionStaffPhoto,
								folder: "staff",
							}),
						]).then(([staffPhotoURL]) => {
							this.createInstitution({
								...this.localInstitution,
								institutionStaffList: {
									...this.localInstitutionStaff,
									photo: staffPhotoURL,
								},
							})
								.then(() => {
									this.is = {
										...this.is,
										loading: false,
									}
									this.reset()
								})
								.catch(() => {
									this.is = {
										...this.is,
										loading: false,
									}
								})
						})
					} else {
						// no institution photo + no institution logo + no staff photo
						this.createInstitution(this.localInstitution)
							.then(() => {
								this.is = {
									...this.is,
									loading: false,
								}

								this.reset()
							})
							.catch(() => {
								this.is = {
									...this.is,
									loading: false,
								}
							})
					}
				} else if (this.institutionPhoto) {
					// update
					if (this.institutionLogo) {
						if (this.institutionStaffPhoto) {
							// institution photo + institution logo + staff photo
							Promise.all([
								this.uploadInstitutionImage({
									file: this.institutionPhoto,
									folder: "photo",
								}),
								this.uploadInstitutionImage({
									file: this.institutionLogo,
									folder: "logo",
								}),
								this.uploadInstitutionImage({
									file: this.institutionStaffPhoto,
									folder: "staff",
								}),
							]).then(([photoURL, logoURL, staffPhotoURL]) => {
								this.updateInstitution({
									...this.localInstitution,
									companyLogo: logoURL,
									institutionStaffList: {
										...this.localInstitutionStaff,
										photo: staffPhotoURL,
									},
									photo: photoURL,
								})
									.then(() => {
										this.is = {
											...this.is,
											loading: false,
										}
										this.reset()
									})
									.catch(() => {
										this.is = {
											...this.is,
											loading: false,
										}
									})
							})
						} else {
							// institution photo + institution logo
							Promise.all([
								this.uploadInstitutionImage({
									file: this.institutionPhoto,
									folder: "photo",
								}),
								this.uploadInstitutionImage({
									file: this.institutionLogo,
									folder: "logo",
								}),
							]).then(([photoURL, logoURL]) => {
								this.updateInstitution({
									...this.localInstitution,
									companyLogo: logoURL,
									photo: photoURL,
								})
									.then(() => {
										this.is = {
											...this.is,
											loading: false,
										}
										this.reset()
									})
									.catch(() => {
										this.is = {
											...this.is,
											loading: false,
										}
									})
							})
						}
					} else if (this.institutionStaffPhoto) {
						// institution photo + staff photo
						Promise.all([
							this.uploadInstitutionImage({
								file: this.institutionPhoto,
								folder: "photo",
							}),
							this.uploadInstitutionImage({
								file: this.institutionStaffPhoto,
								folder: "staff",
							}),
						]).then(([photoURL, staffPhotoURL]) => {
							this.updateInstitution({
								...this.localInstitution,
								institutionStaffList: {
									...this.localInstitutionStaff,
									photo: staffPhotoURL,
								},
								photo: photoURL,
							})
								.then(() => {
									this.is = {
										...this.is,
										loading: false,
									}
									this.reset()
								})
								.catch(() => {
									this.is = {
										...this.is,
										loading: false,
									}
								})
						})
					} else {
						// institution photo
						Promise.all([
							this.uploadInstitutionImage({
								file: this.institutionPhoto,
								folder: "photo",
							}),
						]).then(([photoURL]) => {
							this.updateInstitution({
								...this.localInstitution,
								photo: photoURL,
							})
								.then(() => {
									this.is = {
										...this.is,
										loading: false,
									}
									this.reset()
								})
								.catch(() => {
									this.is = {
										...this.is,
										loading: false,
									}
								})
						})
					}
				} else if (this.institutionLogo) {
					if (this.institutionStaffPhoto) {
						// institution logo + staff photo
						Promise.all([
							this.uploadInstitutionImage({
								file: this.institutionLogo,
								folder: "logo",
							}),
							this.uploadInstitutionImage({
								file: this.institutionStaffPhoto,
								folder: "staff",
							}),
						]).then(([logoURL, staffPhotoURL]) => {
							this.updateInstitution({
								...this.localInstitution,
								companyLogo: logoURL,
								institutionStaffList: {
									...this.localInstitutionStaff,
									photo: staffPhotoURL,
								},
							})
								.then(() => {
									this.is = {
										...this.is,
										loading: false,
									}
									this.reset()
								})
								.catch(() => {
									this.is = {
										...this.is,
										loading: false,
									}
								})
						})
					} else {
						// institution logo
						Promise.all([
							this.uploadInstitutionImage({
								file: this.institutionLogo,
								folder: "logo",
							}),
						]).then(([logoURL]) => {
							this.updateInstitution({
								...this.localInstitution,
								companyLogo: logoURL,
							})
								.then(() => {
									this.is = {
										...this.is,
										loading: false,
									}
									this.reset()
								})
								.catch(() => {
									this.is = {
										...this.is,
										loading: false,
									}
								})
						})
					}
				} else if (this.institutionStaffPhoto) {
					// staff photo
					Promise.all([
						this.uploadInstitutionImage({
							file: this.institutionStaffPhoto,
							folder: "staff",
						}),
					]).then(([staffPhotoURL]) => {
						this.updateInstitution({
							...this.localInstitution,
							institutionStaffList: {
								...this.localInstitutionStaff,
								photo: staffPhotoURL,
							},
						})
							.then(() => {
								this.is = {
									...this.is,
									loading: false,
								}
								this.reset()
							})
							.catch(() => {
								this.is = {
									...this.is,
									loading: false,
								}
							})
					})
				} else {
					// no institution photo + no institution logo + no staff photo
					this.updateInstitution(this.localInstitution)
						.then(() => {
							this.is = {
								...this.is,
								loading: false,
							}

							this.reset()
						})
						.catch(() => {
							this.is = {
								...this.is,
								loading: false,
							}
						})
				}
			},
		},
	}
</script>
